<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <logo />
        <h2 class="brand-text text-primary ml-1">
          Land Vue
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Welcome to Land Vue!
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            v-slot="{ invalid }"
          >

            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- username -->
              <b-form-group
                label="Username"
                label-for="username"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="username"
                  vid="Username"
                  mode="eager"
                  rules="required|max:64"
                >
                  <b-form-input
                    id="username"
                    v-model="userName"
                    :state="errors.length > 0 ? false:null"
                    name="username"
                    placeholder="Enter your username here"
                    autocomplete="new-password"
                  />
                  <small class="text-danger">{{ errors.length > 0 ? errors[0] : null }}</small>
                </validation-provider>
              </b-form-group>
              <!-- <label for="password">Password</label> -->

              <b-form-group
                label="Password"
                label-for="password"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="password"
                  rules="required|min:8"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >

                    <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="············"
                      autocomplete="new-password"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors.length > 0 ? errors[0] : null }}</small>
                </validation-provider>
              </b-form-group>

              <div class="d-flex justify-content-between">
                <!-- forgot password -->
                <b-link :to="{name:'auth-forgot-password-v2'}">
                  <small>Forgot Password?</small>
                </b-link>
              </div><br>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || isDisabled"
              >

                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'page-auth-register-v2'}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>
        </b-col>
      </b-col>
    </b-row></div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import {
  required, min, max, alphaDash,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Auth } from 'aws-amplify'
import { mapGetters } from 'vuex'
import { GetUserGroup, RefreshAuthState } from '@/auth/amplify'
import store from '@/store/index'
import getHomeRouteForLoggedInUser from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    Logo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loginButtonClicked: false,
      isDisabled: false,
      status: '',
      password: '',
      userName: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,

      min,
      max,
      alphaDash,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    // since we using modules you need to specfiy the modules.
    ...mapGetters('authentication', [
      'user',
    ]),
  },
  // ToDO: Fix login button bug https://github.com/OliverHume/marketplace/issues/22
  methods: {
    login() {
      this.loginButtonClicked = true
      this.$refs.loginForm.validate().then(async success => {
        if (success) {
          this.isDisabled = true
          try {
            await Auth.signIn(this.userName, this.password)
            await RefreshAuthState()
            this.$router.replace(getHomeRouteForLoggedInUser(GetUserGroup()))
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Welcome ${this.user.firstName} ${this.user.lastName}`,
                icon: 'CheckIcon',
                variant: 'success',
                text: `You have successfully logged in as ${GetUserGroup()}.`,
              },
              position: 'bottom-right',
            })
            await RefreshAuthState()
          } catch (error) {
            // Sign out on any error! Force user to call support.
            await Auth.signOut()

            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                icon: 'ErrorIcon',
                text: `${error.message}`, // TODO: Need to change to show a generic error in production
              },
            })
            this.isDisabled = false
            this.loginButtonClicked = false
          }
        }
      })
    },
    // log(value) {
    //   console.log(value)
    // },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
